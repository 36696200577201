.teamLogo {
    background: url("#{$flag-path}/teams/teams.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 100px; 
    height: 100px;
    vertical-align: middle;
}
.teamLogo.t-11059 { background-position: 0 0; } 
.teamLogo.t-11061 { background-position: 0 -100px; } 
.teamLogo.t-11063 { background-position: 0 -200px; } 
.teamLogo.t-11065 { background-position: 0 -300px; } 
.teamLogo.t-11067 { background-position: 0 -400px; } 
.teamLogo.t-11068 { background-position: 0 -500px; } 
.teamLogo.t-11069 { background-position: 0 -600px; } 
.teamLogo.t-11070 { background-position: 0 -700px; } 
.teamLogo.t-11072 { background-position: 0 -800px; } 
.teamLogo.t-11073 { background-position: 0 -900px; } 
.teamLogo.t-11075 { background-position: 0 -1000px; } 
.teamLogo.t-11076 { background-position: 0 -1100px; } 
.teamLogo.t-11078 { background-position: 0 -1200px; } 
.teamLogo.t-11079 { background-position: 0 -1300px; } 
.teamLogo.t-11080 { background-position: 0 -1400px; } 
.teamLogo.t-11082 { background-position: 0 -1500px; } 
.teamLogo.t-11083 { background-position: 0 -1600px; } 
.teamLogo.t-10210 { background-position: 0 -1700px; } 
.teamLogo.t-10211 { background-position: 0 -1800px; } 
.teamLogo.t-10212 { background-position: 0 -1900px; } 
.teamLogo.t-10213 { background-position: 0 -2000px; } 
.teamLogo.t-10214 { background-position: 0 -2100px; } 
.teamLogo.t-10215 { background-position: 0 -2200px; } 
.teamLogo.t-10217 { background-position: 0 -2300px; } 
.teamLogo.t-10218 { background-position: 0 -2400px; } 
.teamLogo.t-10220 { background-position: 0 -2500px; } 
.teamLogo.t-10221 { background-position: 0 -2600px; } 
.teamLogo.t-10222 { background-position: 0 -2700px; } 
.teamLogo.t-10223 { background-position: 0 -2800px; } 
.teamLogo.t-10224 { background-position: 0 -2900px; } 
.teamLogo.t-10225 { background-position: 0 -3000px; } 
.teamLogo.t-10226 { background-position: 0 -3100px; } 
.teamLogo.t-10227 { background-position: 0 -3200px; } 
.teamLogo.t-10228 { background-position: 0 -3300px; } 
.teamLogo.t-10229 { background-position: 0 -3400px; } 
.teamLogo.t-10230 { background-position: 0 -3500px; } 
.teamLogo.t-10231 { background-position: 0 -3600px; } 
.teamLogo.t-10232 { background-position: 0 -3700px; } 
.teamLogo.t-10233 { background-position: 0 -3800px; } 
.teamLogo.t-10234 { background-position: 0 -3900px; } 
.teamLogo.t-10235 { background-position: 0 -4000px; } 
.teamLogo.t-10236 { background-position: 0 -4100px; } 
.teamLogo.t-10237 { background-position: 0 -4200px; } 
.teamLogo.t-10238 { background-position: 0 -4300px; } 
.teamLogo.t-10239 { background-position: 0 -4400px; } 
.teamLogo.t-10242 { background-position: 0 -4500px; } 
.teamLogo.t-10243 { background-position: 0 -4600px; } 
.teamLogo.t-10244 { background-position: 0 -4700px; } 
.teamLogo.t-10245 { background-position: 0 -4800px; } 
.teamLogo.t-10246 { background-position: 0 -4900px; } 
.teamLogo.t-10247 { background-position: 0 -5000px; } 
.teamLogo.t-10248 { background-position: 0 -5100px; } 
.teamLogo.t-10249 { background-position: 0 -5200px; } 
.teamLogo.t-10252 { background-position: 0 -5300px; } 
.teamLogo.t-10253 { background-position: 0 -5400px; } 
.teamLogo.t-10254 { background-position: 0 -5500px; } 
.teamLogo.t-10255 { background-position: 0 -5600px; } 
.teamLogo.t-10256 { background-position: 0 -5700px; } 
.teamLogo.t-10257 { background-position: 0 -5800px; } 
.teamLogo.t-10258 { background-position: 0 -5900px; } 
.teamLogo.t-10259 { background-position: 0 -6000px; } 
.teamLogo.t-10260 { background-position: 0 -6100px; } 
.teamLogo.t-10261 { background-position: 0 -6200px; } 
.teamLogo.t-10262 { background-position: 0 -6300px; } 
.teamLogo.t-10263 { background-position: 0 -6400px; } 
.teamLogo.t-10264 { background-position: 0 -6500px; } 
.teamLogo.t-10265 { background-position: 0 -6600px; } 
.teamLogo.t-10266 { background-position: 0 -6700px; } 
.teamLogo.t-10267 { background-position: 0 -6800px; } 
.teamLogo.t-10268 { background-position: 0 -6900px; } 
.teamLogo.t-10269 { background-position: 0 -7000px; } 
.teamLogo.t-10270 { background-position: 0 -7100px; } 
.teamLogo.t-10271 { background-position: 0 -7200px; } 
.teamLogo.t-10272 { background-position: 0 -7300px; } 
.teamLogo.t-10273 { background-position: 0 -7400px; } 
.teamLogo.t-10274 { background-position: 0 -7500px; } 
.teamLogo.t-10275 { background-position: 0 -7600px; } 
.teamLogo.t-10277 { background-position: 0 -7700px; } 
.teamLogo.t-10278 { background-position: 0 -7800px; } 
.teamLogo.t-10279 { background-position: 0 -7900px; } 
.teamLogo.t-10280 { background-position: 0 -8000px; } 
.teamLogo.t-10281 { background-position: 0 -8100px; } 
.teamLogo.t-10282 { background-position: 0 -8200px; } 
.teamLogo.t-10283 { background-position: 0 -8300px; } 
.teamLogo.t-10284 { background-position: 0 -8400px; } 
.teamLogo.t-10285 { background-position: 0 -8500px; } 
.teamLogo.t-10286 { background-position: 0 -8600px; } 
.teamLogo.t-10287 { background-position: 0 -8700px; } 
.teamLogo.t-10288 { background-position: 0 -8800px; } 
.teamLogo.t-10289 { background-position: 0 -8900px; } 
.teamLogo.t-10290 { background-position: 0 -9000px; } 
.teamLogo.t-10291 { background-position: 0 -9100px; } 
.teamLogo.t-10292 { background-position: 0 -9200px; } 
.teamLogo.t-10293 { background-position: 0 -9300px; } 
.teamLogo.t-10294 { background-position: 0 -9400px; } 
.teamLogo.t-10295 { background-position: 0 -9500px; } 
.teamLogo.t-10296 { background-position: 0 -9600px; } 
.teamLogo.t-10297 { background-position: 0 -9700px; } 
.teamLogo.t-10298 { background-position: 0 -9800px; } 
.teamLogo.t-10299 { background-position: 0 -9900px; } 
.teamLogo.t-10300 { background-position: 0 -10000px; } 
.teamLogo.t-10303 { background-position: 0 -10100px; } 
.teamLogo.t-10304 { background-position: 0 -10200px; } 
.teamLogo.t-10305 { background-position: 0 -10300px; } 
.teamLogo.t-10306 { background-position: 0 -10400px; } 
.teamLogo.t-10307 { background-position: 0 -10500px; } 
.teamLogo.t-10308 { background-position: 0 -10600px; } 
.teamLogo.t-10309 { background-position: 0 -10700px; } 
.teamLogo.t-10310 { background-position: 0 -10800px; } 
.teamLogo.t-10311 { background-position: 0 -10900px; } 
.teamLogo.t-10312 { background-position: 0 -11000px; } 
.teamLogo.t-10313 { background-position: 0 -11100px; } 
.teamLogo.t-10314 { background-position: 0 -11200px; } 
.teamLogo.t-10315 { background-position: 0 -11300px; } 
.teamLogo.t-10318 { background-position: 0 -11400px; } 
.teamLogo.t-10320 { background-position: 0 -11500px; } 
.teamLogo.t-10321 { background-position: 0 -11600px; } 
.teamLogo.t-10323 { background-position: 0 -11700px; } 
.teamLogo.t-10324 { background-position: 0 -11800px; } 
.teamLogo.t-10325 { background-position: 0 -11900px; } 
.teamLogo.t-10326 { background-position: 0 -12000px; } 
.teamLogo.t-10327 { background-position: 0 -12100px; } 
.teamLogo.t-10328 { background-position: 0 -12200px; } 
.teamLogo.t-10329 { background-position: 0 -12300px; } 
.teamLogo.t-10330 { background-position: 0 -12400px; } 
.teamLogo.t-10331 { background-position: 0 -12500px; } 
.teamLogo.t-10332 { background-position: 0 -12600px; } 
.teamLogo.t-10333 { background-position: 0 -12700px; } 
.teamLogo.t-10334 { background-position: 0 -12800px; } 
.teamLogo.t-10335 { background-position: 0 -12900px; } 
.teamLogo.t-10338 { background-position: 0 -13000px; } 
.teamLogo.t-10339 { background-position: 0 -13100px; } 
.teamLogo.t-10340 { background-position: 0 -13200px; } 
.teamLogo.t-10341 { background-position: 0 -13300px; } 
.teamLogo.t-10342 { background-position: 0 -13400px; } 
.teamLogo.t-10344 { background-position: 0 -13500px; } 
.teamLogo.t-10345 { background-position: 0 -13600px; } 
.teamLogo.t-10347 { background-position: 0 -13700px; } 
.teamLogo.t-10348 { background-position: 0 -13800px; } 
.teamLogo.t-10349 { background-position: 0 -13900px; } 
.teamLogo.t-10350 { background-position: 0 -14000px; } 
.teamLogo.t-10351 { background-position: 0 -14100px; } 
.teamLogo.t-10352 { background-position: 0 -14200px; } 
.teamLogo.t-10354 { background-position: 0 -14300px; } 
.teamLogo.t-10355 { background-position: 0 -14400px; } 
.teamLogo.t-10356 { background-position: 0 -14500px; } 
.teamLogo.t-10357 { background-position: 0 -14600px; } 
.teamLogo.t-10358 { background-position: 0 -14700px; } 
.teamLogo.t-10359 { background-position: 0 -14800px; } 
.teamLogo.t-10360 { background-position: 0 -14900px; } 
.teamLogo.t-10361 { background-position: 0 -15000px; } 
.teamLogo.t-10362 { background-position: 0 -15100px; } 
.teamLogo.t-10363 { background-position: 0 -15200px; } 
.teamLogo.t-10364 { background-position: 0 -15300px; } 
.teamLogo.t-10365 { background-position: 0 -15400px; } 
.teamLogo.t-10366 { background-position: 0 -15500px; } 
.teamLogo.t-10367 { background-position: 0 -15600px; } 
.teamLogo.t-10368 { background-position: 0 -15700px; } 
.teamLogo.t-10369 { background-position: 0 -15800px; } 
.teamLogo.t-10370 { background-position: 0 -15900px; } 
.teamLogo.t-10375 { background-position: 0 -16000px; } 
.teamLogo.t-10377 { background-position: 0 -16100px; } 
.teamLogo.t-10389 { background-position: 0 -16200px; } 
.teamLogo.t-10390 { background-position: 0 -16300px; } 
.teamLogo.t-10391 { background-position: 0 -16400px; } 
.teamLogo.t-10392 { background-position: 0 -16500px; } 
.teamLogo.t-10393 { background-position: 0 -16600px; } 
.teamLogo.t-10394 { background-position: 0 -16700px; } 
.teamLogo.t-10395 { background-position: 0 -16800px; } 
.teamLogo.t-10396 { background-position: 0 -16900px; } 
.teamLogo.t-10398 { background-position: 0 -17000px; } 
.teamLogo.t-10400 { background-position: 0 -17100px; } 
.teamLogo.t-10401 { background-position: 0 -17200px; } 
.teamLogo.t-10402 { background-position: 0 -17300px; } 
.teamLogo.t-10404 { background-position: 0 -17400px; } 
.teamLogo.t-10406 { background-position: 0 -17500px; } 
.teamLogo.t-10407 { background-position: 0 -17600px; } 
.teamLogo.t-10408 { background-position: 0 -17700px; } 
.teamLogo.t-10409 { background-position: 0 -17800px; } 
.teamLogo.t-10410 { background-position: 0 -17900px; } 
.teamLogo.t-10411 { background-position: 0 -18000px; } 
.teamLogo.t-10412 { background-position: 0 -18100px; } 
.teamLogo.t-10413 { background-position: 0 -18200px; } 
.teamLogo.t-10414 { background-position: 0 -18300px; } 
.teamLogo.t-10415 { background-position: 0 -18400px; } 
.teamLogo.t-10416 { background-position: 0 -18500px; } 
.teamLogo.t-10417 { background-position: 0 -18600px; } 
.teamLogo.t-10418 { background-position: 0 -18700px; } 
.teamLogo.t-10419 { background-position: 0 -18800px; } 
.teamLogo.t-10420 { background-position: 0 -18900px; } 
.teamLogo.t-10421 { background-position: 0 -19000px; } 
.teamLogo.t-10422 { background-position: 0 -19100px; } 
.teamLogo.t-10423 { background-position: 0 -19200px; } 
.teamLogo.t-10424 { background-position: 0 -19300px; } 
.teamLogo.t-10425 { background-position: 0 -19400px; } 
.teamLogo.t-10426 { background-position: 0 -19500px; } 
.teamLogo.t-10427 { background-position: 0 -19600px; } 
.teamLogo.t-10428 { background-position: 0 -19700px; } 
.teamLogo.t-10429 { background-position: 0 -19800px; } 
.teamLogo.t-10430 { background-position: 0 -19900px; } 
.teamLogo.t-10431 { background-position: 0 -20000px; } 
.teamLogo.t-10432 { background-position: 0 -20100px; } 
.teamLogo.t-10433 { background-position: 0 -20200px; } 
.teamLogo.t-10434 { background-position: 0 -20300px; } 
.teamLogo.t-10436 { background-position: 0 -20400px; } 
.teamLogo.t-10437 { background-position: 0 -20500px; } 
.teamLogo.t-10439 { background-position: 0 -20600px; } 
.teamLogo.t-10440 { background-position: 0 -20700px; } 
.teamLogo.t-10441 { background-position: 0 -20800px; } 
.teamLogo.t-10442 { background-position: 0 -20900px; } 
.teamLogo.t-10443 { background-position: 0 -21000px; } 
.teamLogo.t-10444 { background-position: 0 -21100px; } 
.teamLogo.t-10445 { background-position: 0 -21200px; } 
.teamLogo.t-10454 { background-position: 0 -21300px; } 
.teamLogo.t-10455 { background-position: 0 -21400px; } 
.teamLogo.t-10456 { background-position: 0 -21500px; } 
.teamLogo.t-10457 { background-position: 0 -21600px; } 
.teamLogo.t-10458 { background-position: 0 -21700px; } 
.teamLogo.t-10459 { background-position: 0 -21800px; } 
.teamLogo.t-10460 { background-position: 0 -21900px; } 
.teamLogo.t-10461 { background-position: 0 -22000px; } 
.teamLogo.t-10462 { background-position: 0 -22100px; } 
.teamLogo.t-10463 { background-position: 0 -22200px; } 
.teamLogo.t-10464 { background-position: 0 -22300px; } 
.teamLogo.t-10465 { background-position: 0 -22400px; } 
.teamLogo.t-10466 { background-position: 0 -22500px; } 
.teamLogo.t-10467 { background-position: 0 -22600px; } 
.teamLogo.t-10468 { background-position: 0 -22700px; } 
.teamLogo.t-10469 { background-position: 0 -22800px; } 
.teamLogo.t-10470 { background-position: 0 -22900px; } 
.teamLogo.t-10471 { background-position: 0 -23000px; } 
.teamLogo.t-10472 { background-position: 0 -23100px; } 
.teamLogo.t-10473 { background-position: 0 -23200px; } 
.teamLogo.t-10474 { background-position: 0 -23300px; } 
.teamLogo.t-10475 { background-position: 0 -23400px; } 
.teamLogo.t-10476 { background-position: 0 -23500px; } 
.teamLogo.t-10477 { background-position: 0 -23600px; } 
.teamLogo.t-10478 { background-position: 0 -23700px; } 
.teamLogo.t-10479 { background-position: 0 -23800px; } 
.teamLogo.t-10480 { background-position: 0 -23900px; } 
.teamLogo.t-10481 { background-position: 0 -24000px; } 
.teamLogo.t-10482 { background-position: 0 -24100px; } 
.teamLogo.t-10483 { background-position: 0 -24200px; } 
.teamLogo.t-10484 { background-position: 0 -24300px; } 
.teamLogo.t-10485 { background-position: 0 -24400px; } 
.teamLogo.t-10486 { background-position: 0 -24500px; } 
.teamLogo.t-10487 { background-position: 0 -24600px; } 
.teamLogo.t-10488 { background-position: 0 -24700px; } 
.teamLogo.t-10489 { background-position: 0 -24800px; } 
.teamLogo.t-10490 { background-position: 0 -24900px; } 
.teamLogo.t-10491 { background-position: 0 -25000px; } 
.teamLogo.t-10492 { background-position: 0 -25100px; } 
.teamLogo.t-10493 { background-position: 0 -25200px; } 
.teamLogo.t-10494 { background-position: 0 -25300px; } 
.teamLogo.t-10495 { background-position: 0 -25400px; } 
.teamLogo.t-10496 { background-position: 0 -25500px; } 
.teamLogo.t-10497 { background-position: 0 -25600px; } 
.teamLogo.t-10498 { background-position: 0 -25700px; } 
.teamLogo.t-10499 { background-position: 0 -25800px; } 
.teamLogo.t-10500 { background-position: 0 -25900px; } 
.teamLogo.t-10501 { background-position: 0 -26000px; } 
.teamLogo.t-10502 { background-position: 0 -26100px; } 
.teamLogo.t-10503 { background-position: 0 -26200px; } 
.teamLogo.t-10504 { background-position: 0 -26300px; } 
.teamLogo.t-10505 { background-position: 0 -26400px; } 
.teamLogo.t-10506 { background-position: 0 -26500px; } 
.teamLogo.t-10507 { background-position: 0 -26600px; } 
.teamLogo.t-10508 { background-position: 0 -26700px; } 
.teamLogo.t-10509 { background-position: 0 -26800px; } 
.teamLogo.t-10510 { background-position: 0 -26900px; } 
.teamLogo.t-10511 { background-position: 0 -27000px; } 
.teamLogo.t-10512 { background-position: 0 -27100px; } 
.teamLogo.t-10513 { background-position: 0 -27200px; } 
.teamLogo.t-10514 { background-position: 0 -27300px; } 
.teamLogo.t-10515 { background-position: 0 -27400px; } 
.teamLogo.t-10516 { background-position: 0 -27500px; } 
.teamLogo.t-10517 { background-position: 0 -27600px; } 
.teamLogo.t-10518 { background-position: 0 -27700px; } 
.teamLogo.t-10519 { background-position: 0 -27800px; } 
.teamLogo.t-10520 { background-position: 0 -27900px; } 
.teamLogo.t-10521 { background-position: 0 -28000px; } 
.teamLogo.t-10522 { background-position: 0 -28100px; } 
.teamLogo.t-10523 { background-position: 0 -28200px; } 
.teamLogo.t-10524 { background-position: 0 -28300px; } 
.teamLogo.t-10525 { background-position: 0 -28400px; } 
.teamLogo.t-10526 { background-position: 0 -28500px; } 
.teamLogo.t-10527 { background-position: 0 -28600px; } 
.teamLogo.t-10529 { background-position: 0 -28700px; } 
.teamLogo.t-10533 { background-position: 0 -28800px; } 
.teamLogo.t-10534 { background-position: 0 -28900px; } 
.teamLogo.t-10536 { background-position: 0 -29000px; } 
.teamLogo.t-10537 { background-position: 0 -29100px; } 
.teamLogo.t-10538 { background-position: 0 -29200px; } 
.teamLogo.t-10539 { background-position: 0 -29300px; } 
.teamLogo.t-10540 { background-position: 0 -29400px; } 
.teamLogo.t-10541 { background-position: 0 -29500px; } 
.teamLogo.t-10542 { background-position: 0 -29600px; } 
.teamLogo.t-10543 { background-position: 0 -29700px; } 
.teamLogo.t-10544 { background-position: 0 -29800px; } 
.teamLogo.t-10545 { background-position: 0 -29900px; } 
.teamLogo.t-10546 { background-position: 0 -30000px; } 
.teamLogo.t-10547 { background-position: 0 -30100px; } 
.teamLogo.t-10548 { background-position: 0 -30200px; } 
.teamLogo.t-10549 { background-position: 0 -30300px; } 
.teamLogo.t-10550 { background-position: 0 -30400px; } 
.teamLogo.t-10551 { background-position: 0 -30500px; } 
.teamLogo.t-10553 { background-position: 0 -30600px; } 
.teamLogo.t-10554 { background-position: 0 -30700px; } 
.teamLogo.t-10556 { background-position: 0 -30800px; } 
.teamLogo.t-10557 { background-position: 0 -30900px; } 
.teamLogo.t-10558 { background-position: 0 -31000px; } 
.teamLogo.t-10560 { background-position: 0 -31100px; } 
.teamLogo.t-10561 { background-position: 0 -31200px; } 
.teamLogo.t-10562 { background-position: 0 -31300px; } 
.teamLogo.t-10564 { background-position: 0 -31400px; } 
.teamLogo.t-10565 { background-position: 0 -31500px; } 
.teamLogo.t-10566 { background-position: 0 -31600px; } 
.teamLogo.t-10567 { background-position: 0 -31700px; } 
.teamLogo.t-10568 { background-position: 0 -31800px; } 
.teamLogo.t-10569 { background-position: 0 -31900px; } 
.teamLogo.t-10570 { background-position: 0 -32000px; } 
.teamLogo.t-10573 { background-position: 0 -32100px; } 
.teamLogo.t-10574 { background-position: 0 -32200px; } 
.teamLogo.t-10577 { background-position: 0 -32300px; } 
.teamLogo.t-10579 { background-position: 0 -32400px; } 
.teamLogo.t-10583 { background-position: 0 -32500px; } 
.teamLogo.t-10586 { background-position: 0 -32600px; } 
.teamLogo.t-10587 { background-position: 0 -32700px; } 
.teamLogo.t-10588 { background-position: 0 -32800px; } 
.teamLogo.t-10589 { background-position: 0 -32900px; } 
.teamLogo.t-10590 { background-position: 0 -33000px; } 
.teamLogo.t-10591 { background-position: 0 -33100px; } 
.teamLogo.t-10596 { background-position: 0 -33200px; } 
.teamLogo.t-10597 { background-position: 0 -33300px; } 
.teamLogo.t-10600 { background-position: 0 -33400px; } 
.teamLogo.t-10606 { background-position: 0 -33500px; } 
.teamLogo.t-10607 { background-position: 0 -33600px; } 
.teamLogo.t-10619 { background-position: 0 -33700px; } 
.teamLogo.t-10625 { background-position: 0 -33800px; } 
.teamLogo.t-10628 { background-position: 0 -33900px; } 
.teamLogo.t-10632 { background-position: 0 -34000px; } 
.teamLogo.t-10634 { background-position: 0 -34100px; } 
.teamLogo.t-10638 { background-position: 0 -34200px; } 
.teamLogo.t-10640 { background-position: 0 -34300px; } 
.teamLogo.t-10641 { background-position: 0 -34400px; } 
.teamLogo.t-10642 { background-position: 0 -34500px; } 
.teamLogo.t-10643 { background-position: 0 -34600px; } 
.teamLogo.t-10662 { background-position: 0 -34700px; } 
.teamLogo.t-10663 { background-position: 0 -34800px; } 
.teamLogo.t-10664 { background-position: 0 -34900px; } 
.teamLogo.t-10665 { background-position: 0 -35000px; } 
.teamLogo.t-10666 { background-position: 0 -35100px; } 
.teamLogo.t-10667 { background-position: 0 -35200px; } 
.teamLogo.t-10668 { background-position: 0 -35300px; } 
.teamLogo.t-10669 { background-position: 0 -35400px; } 
.teamLogo.t-10670 { background-position: 0 -35500px; } 
.teamLogo.t-10671 { background-position: 0 -35600px; } 
.teamLogo.t-10672 { background-position: 0 -35700px; } 
.teamLogo.t-10673 { background-position: 0 -35800px; } 
.teamLogo.t-10674 { background-position: 0 -35900px; } 
.teamLogo.t-10675 { background-position: 0 -36000px; } 
.teamLogo.t-10676 { background-position: 0 -36100px; } 
.teamLogo.t-10677 { background-position: 0 -36200px; } 
.teamLogo.t-10678 { background-position: 0 -36300px; } 
.teamLogo.t-10679 { background-position: 0 -36400px; } 
.teamLogo.t-10680 { background-position: 0 -36500px; } 
.teamLogo.t-10681 { background-position: 0 -36600px; } 
.teamLogo.t-10682 { background-position: 0 -36700px; } 
.teamLogo.t-10683 { background-position: 0 -36800px; } 
.teamLogo.t-10684 { background-position: 0 -36900px; } 
.teamLogo.t-10685 { background-position: 0 -37000px; } 
.teamLogo.t-10686 { background-position: 0 -37100px; } 
.teamLogo.t-10687 { background-position: 0 -37200px; } 
.teamLogo.t-10688 { background-position: 0 -37300px; } 
.teamLogo.t-10689 { background-position: 0 -37400px; } 
.teamLogo.t-10690 { background-position: 0 -37500px; } 
.teamLogo.t-10691 { background-position: 0 -37600px; } 
.teamLogo.t-10692 { background-position: 0 -37700px; } 
.teamLogo.t-10693 { background-position: 0 -37800px; } 
.teamLogo.t-10694 { background-position: 0 -37900px; } 
.teamLogo.t-10695 { background-position: 0 -38000px; } 
.teamLogo.t-10696 { background-position: 0 -38100px; } 
.teamLogo.t-10697 { background-position: 0 -38200px; } 
.teamLogo.t-10698 { background-position: 0 -38300px; } 
.teamLogo.t-10699 { background-position: 0 -38400px; } 
.teamLogo.t-10700 { background-position: 0 -38500px; } 
.teamLogo.t-10701 { background-position: 0 -38600px; } 
.teamLogo.t-10702 { background-position: 0 -38700px; } 
.teamLogo.t-10703 { background-position: 0 -38800px; } 
.teamLogo.t-10704 { background-position: 0 -38900px; } 
.teamLogo.t-10705 { background-position: 0 -39000px; } 
.teamLogo.t-10706 { background-position: 0 -39100px; } 
.teamLogo.t-10707 { background-position: 0 -39200px; } 
.teamLogo.t-10708 { background-position: 0 -39300px; } 
.teamLogo.t-10709 { background-position: 0 -39400px; } 
.teamLogo.t-10710 { background-position: 0 -39500px; } 
.teamLogo.t-10712 { background-position: 0 -39600px; } 
.teamLogo.t-10715 { background-position: 0 -39700px; } 
.teamLogo.t-10719 { background-position: 0 -39800px; } 
.teamLogo.t-10720 { background-position: 0 -39900px; } 
.teamLogo.t-10727 { background-position: 0 -40000px; } 
.teamLogo.t-10729 { background-position: 0 -40100px; } 
.teamLogo.t-10750 { background-position: 0 -40200px; } 
.teamLogo.t-10752 { background-position: 0 -40300px; } 
.teamLogo.t-10754 { background-position: 0 -40400px; } 
.teamLogo.t-10757 { background-position: 0 -40500px; } 
.teamLogo.t-10759 { background-position: 0 -40600px; } 
.teamLogo.t-10761 { background-position: 0 -40700px; } 
.teamLogo.t-10762 { background-position: 0 -40800px; } 
.teamLogo.t-10763 { background-position: 0 -40900px; } 
.teamLogo.t-10764 { background-position: 0 -41000px; } 
.teamLogo.t-10767 { background-position: 0 -41100px; } 
.teamLogo.t-10768 { background-position: 0 -41200px; } 
.teamLogo.t-10770 { background-position: 0 -41300px; } 
.teamLogo.t-10771 { background-position: 0 -41400px; } 
.teamLogo.t-10773 { background-position: 0 -41500px; } 
.teamLogo.t-10775 { background-position: 0 -41600px; } 
.teamLogo.t-10776 { background-position: 0 -41700px; } 
.teamLogo.t-10779 { background-position: 0 -41800px; } 
.teamLogo.t-10780 { background-position: 0 -41900px; } 
.teamLogo.t-10781 { background-position: 0 -42000px; } 
.teamLogo.t-10782 { background-position: 0 -42100px; } 
.teamLogo.t-10785 { background-position: 0 -42200px; } 
.teamLogo.t-10787 { background-position: 0 -42300px; } 
.teamLogo.t-10788 { background-position: 0 -42400px; } 
.teamLogo.t-10792 { background-position: 0 -42500px; } 
.teamLogo.t-10796 { background-position: 0 -42600px; } 
.teamLogo.t-10798 { background-position: 0 -42700px; } 
.teamLogo.t-10800 { background-position: 0 -42800px; } 
.teamLogo.t-10802 { background-position: 0 -42900px; } 
.teamLogo.t-10803 { background-position: 0 -43000px; } 
.teamLogo.t-10804 { background-position: 0 -43100px; } 
.teamLogo.t-10805 { background-position: 0 -43200px; } 
.teamLogo.t-10810 { background-position: 0 -43300px; } 
.teamLogo.t-10811 { background-position: 0 -43400px; } 
.teamLogo.t-10815 { background-position: 0 -43500px; } 
.teamLogo.t-10816 { background-position: 0 -43600px; } 
.teamLogo.t-10817 { background-position: 0 -43700px; } 
.teamLogo.t-10818 { background-position: 0 -43800px; } 
.teamLogo.t-10819 { background-position: 0 -43900px; } 
.teamLogo.t-10820 { background-position: 0 -44000px; } 
.teamLogo.t-10821 { background-position: 0 -44100px; } 
.teamLogo.t-10822 { background-position: 0 -44200px; } 
.teamLogo.t-10823 { background-position: 0 -44300px; } 
.teamLogo.t-10824 { background-position: 0 -44400px; } 
.teamLogo.t-10825 { background-position: 0 -44500px; } 
.teamLogo.t-10826 { background-position: 0 -44600px; } 
.teamLogo.t-10827 { background-position: 0 -44700px; } 
.teamLogo.t-10828 { background-position: 0 -44800px; } 
.teamLogo.t-10829 { background-position: 0 -44900px; } 
.teamLogo.t-10830 { background-position: 0 -45000px; } 
.teamLogo.t-10831 { background-position: 0 -45100px; } 
.teamLogo.t-10832 { background-position: 0 -45200px; } 
.teamLogo.t-10833 { background-position: 0 -45300px; } 
.teamLogo.t-10834 { background-position: 0 -45400px; } 
.teamLogo.t-10835 { background-position: 0 -45500px; } 
.teamLogo.t-10836 { background-position: 0 -45600px; } 
.teamLogo.t-10838 { background-position: 0 -45700px; } 
.teamLogo.t-10839 { background-position: 0 -45800px; } 
.teamLogo.t-10840 { background-position: 0 -45900px; } 
.teamLogo.t-10842 { background-position: 0 -46000px; } 
.teamLogo.t-10843 { background-position: 0 -46100px; } 
.teamLogo.t-10844 { background-position: 0 -46200px; } 
.teamLogo.t-10845 { background-position: 0 -46300px; } 
.teamLogo.t-10846 { background-position: 0 -46400px; } 
.teamLogo.t-10848 { background-position: 0 -46500px; } 
.teamLogo.t-10849 { background-position: 0 -46600px; } 
.teamLogo.t-10850 { background-position: 0 -46700px; } 
.teamLogo.t-10854 { background-position: 0 -46800px; } 
.teamLogo.t-10855 { background-position: 0 -46900px; } 
.teamLogo.t-10856 { background-position: 0 -47000px; } 
.teamLogo.t-10857 { background-position: 0 -47100px; } 
.teamLogo.t-10858 { background-position: 0 -47200px; } 
.teamLogo.t-10859 { background-position: 0 -47300px; } 
.teamLogo.t-10860 { background-position: 0 -47400px; } 
.teamLogo.t-10863 { background-position: 0 -47500px; } 
.teamLogo.t-10864 { background-position: 0 -47600px; } 
.teamLogo.t-10865 { background-position: 0 -47700px; } 
.teamLogo.t-10867 { background-position: 0 -47800px; } 
.teamLogo.t-10868 { background-position: 0 -47900px; } 
.teamLogo.t-10869 { background-position: 0 -48000px; } 
.teamLogo.t-10870 { background-position: 0 -48100px; } 
.teamLogo.t-10871 { background-position: 0 -48200px; } 
.teamLogo.t-10872 { background-position: 0 -48300px; } 
.teamLogo.t-10873 { background-position: 0 -48400px; } 
.teamLogo.t-10874 { background-position: 0 -48500px; } 
.teamLogo.t-10875 { background-position: 0 -48600px; } 
.teamLogo.t-10877 { background-position: 0 -48700px; } 
.teamLogo.t-10880 { background-position: 0 -48800px; } 
.teamLogo.t-10882 { background-position: 0 -48900px; } 
.teamLogo.t-10883 { background-position: 0 -49000px; } 
.teamLogo.t-10885 { background-position: 0 -49100px; } 
.teamLogo.t-10886 { background-position: 0 -49200px; } 
.teamLogo.t-10887 { background-position: 0 -49300px; } 
.teamLogo.t-10888 { background-position: 0 -49400px; } 
.teamLogo.t-10891 { background-position: 0 -49500px; } 
.teamLogo.t-10894 { background-position: 0 -49600px; } 
.teamLogo.t-10895 { background-position: 0 -49700px; } 
.teamLogo.t-10896 { background-position: 0 -49800px; } 
.teamLogo.t-10897 { background-position: 0 -49900px; } 
.teamLogo.t-10898 { background-position: 0 -50000px; } 
.teamLogo.t-10903 { background-position: 0 -50100px; } 
.teamLogo.t-10904 { background-position: 0 -50200px; } 
.teamLogo.t-10905 { background-position: 0 -50300px; } 
.teamLogo.t-10907 { background-position: 0 -50400px; } 
.teamLogo.t-10909 { background-position: 0 -50500px; } 
.teamLogo.t-10910 { background-position: 0 -50600px; } 
.teamLogo.t-10911 { background-position: 0 -50700px; } 
.teamLogo.t-10913 { background-position: 0 -50800px; } 
.teamLogo.t-10914 { background-position: 0 -50900px; } 
.teamLogo.t-10925 { background-position: 0 -51000px; } 
.teamLogo.t-10927 { background-position: 0 -51100px; } 
.teamLogo.t-10929 { background-position: 0 -51200px; } 
.teamLogo.t-10930 { background-position: 0 -51300px; } 
.teamLogo.t-10931 { background-position: 0 -51400px; } 
.teamLogo.t-10933 { background-position: 0 -51500px; } 
.teamLogo.t-10934 { background-position: 0 -51600px; } 
.teamLogo.t-10935 { background-position: 0 -51700px; } 
.teamLogo.t-10937 { background-position: 0 -51800px; } 
.teamLogo.t-10938 { background-position: 0 -51900px; } 
.teamLogo.t-10939 { background-position: 0 -52000px; } 
.teamLogo.t-10941 { background-position: 0 -52100px; } 
.teamLogo.t-10942 { background-position: 0 -52200px; } 
.teamLogo.t-10943 { background-position: 0 -52300px; } 
.teamLogo.t-10947 { background-position: 0 -52400px; } 
.teamLogo.t-10948 { background-position: 0 -52500px; } 
.teamLogo.t-10950 { background-position: 0 -52600px; } 
.teamLogo.t-10951 { background-position: 0 -52700px; } 
.teamLogo.t-10956 { background-position: 0 -52800px; } 
.teamLogo.t-10957 { background-position: 0 -52900px; } 
.teamLogo.t-10958 { background-position: 0 -53000px; } 
.teamLogo.t-10960 { background-position: 0 -53100px; } 
.teamLogo.t-10961 { background-position: 0 -53200px; } 
.teamLogo.t-10962 { background-position: 0 -53300px; } 
.teamLogo.t-10964 { background-position: 0 -53400px; } 
.teamLogo.t-10965 { background-position: 0 -53500px; } 
.teamLogo.t-10967 { background-position: 0 -53600px; } 
.teamLogo.t-10974 { background-position: 0 -53700px; } 
.teamLogo.t-10977 { background-position: 0 -53800px; } 
.teamLogo.t-10978 { background-position: 0 -53900px; } 
.teamLogo.t-10980 { background-position: 0 -54000px; } 
.teamLogo.t-10985 { background-position: 0 -54100px; } 
.teamLogo.t-10986 { background-position: 0 -54200px; } 
.teamLogo.t-10988 { background-position: 0 -54300px; } 
.teamLogo.t-10989 { background-position: 0 -54400px; } 
.teamLogo.t-10990 { background-position: 0 -54500px; } 
.teamLogo.t-10991 { background-position: 0 -54600px; } 
.teamLogo.t-10992 { background-position: 0 -54700px; } 
.teamLogo.t-10994 { background-position: 0 -54800px; } 
.teamLogo.t-10995 { background-position: 0 -54900px; } 
.teamLogo.t-10997 { background-position: 0 -55000px; } 
.teamLogo.t-10998 { background-position: 0 -55100px; } 
.teamLogo.t-11000 { background-position: 0 -55200px; } 
.teamLogo.t-11001 { background-position: 0 -55300px; } 
.teamLogo.t-11002 { background-position: 0 -55400px; } 
.teamLogo.t-11003 { background-position: 0 -55500px; } 
.teamLogo.t-11004 { background-position: 0 -55600px; } 
.teamLogo.t-11005 { background-position: 0 -55700px; } 
.teamLogo.t-11006 { background-position: 0 -55800px; } 
.teamLogo.t-11007 { background-position: 0 -55900px; } 
.teamLogo.t-11008 { background-position: 0 -56000px; } 
.teamLogo.t-11009 { background-position: 0 -56100px; } 
.teamLogo.t-11010 { background-position: 0 -56200px; } 
.teamLogo.t-11011 { background-position: 0 -56300px; } 
.teamLogo.t-11012 { background-position: 0 -56400px; } 
.teamLogo.t-11013 { background-position: 0 -56500px; } 
.teamLogo.t-11014 { background-position: 0 -56600px; } 
.teamLogo.t-11015 { background-position: 0 -56700px; } 
.teamLogo.t-11016 { background-position: 0 -56800px; } 
.teamLogo.t-11017 { background-position: 0 -56900px; } 
.teamLogo.t-11018 { background-position: 0 -57000px; } 
.teamLogo.t-11019 { background-position: 0 -57100px; } 
.teamLogo.t-11020 { background-position: 0 -57200px; } 
.teamLogo.t-11021 { background-position: 0 -57300px; } 
.teamLogo.t-11022 { background-position: 0 -57400px; } 
.teamLogo.t-11023 { background-position: 0 -57500px; } 
.teamLogo.t-11026 { background-position: 0 -57600px; } 
.teamLogo.t-11027 { background-position: 0 -57700px; } 
.teamLogo.t-11028 { background-position: 0 -57800px; } 
.teamLogo.t-11029 { background-position: 0 -57900px; } 
.teamLogo.t-11030 { background-position: 0 -58000px; } 
.teamLogo.t-11031 { background-position: 0 -58100px; } 
.teamLogo.t-11032 { background-position: 0 -58200px; } 
.teamLogo.t-11033 { background-position: 0 -58300px; } 
.teamLogo.t-11034 { background-position: 0 -58400px; } 
.teamLogo.t-11035 { background-position: 0 -58500px; } 
.teamLogo.t-11036 { background-position: 0 -58600px; } 
.teamLogo.t-11039 { background-position: 0 -58700px; } 
.teamLogo.t-11040 { background-position: 0 -58800px; } 
.teamLogo.t-11041 { background-position: 0 -58900px; } 
.teamLogo.t-11043 { background-position: 0 -59000px; } 
.teamLogo.t-11044 { background-position: 0 -59100px; } 
.teamLogo.t-11045 { background-position: 0 -59200px; } 
.teamLogo.t-11046 { background-position: 0 -59300px; } 
.teamLogo.t-11050 { background-position: 0 -59400px; } 
.teamLogo.t-11051 { background-position: 0 -59500px; } 
.teamLogo.t-11052 { background-position: 0 -59600px; } 
.teamLogo.t-11053 { background-position: 0 -59700px; } 
.teamLogo.t-11055 { background-position: 0 -59800px; } 
.teamLogo.t-11058 { background-position: 0 -59900px; } 