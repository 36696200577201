.container {
    max-width: $page__maxWidth;
    // min-width: $page__minWidth;
    margin: 0 auto;
    background-color: $background-black;
    padding: 5px;
}
.pageContent {
    padding: 0px;
    background-color: $body-bg;
}
.mainLanding-page {
    margin: 0px auto;
    background-color: $body-bg;
    // background-color: #ececec;
}
.graphSection {
    display: flex;
    flex-direction: column;
    .graphContainer {
        padding: 0px;
    }
    .graphContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: 20px;
        font-family: HelveticaCondensed;
        /* border-radius: 5px; */
        min-height: 200px;
        background-image: linear-gradient(#133667, #27497e);
        @media screen and (max-width: $tab_Device){
            flex-direction: column;
        }
        
    }
        .TwoInRow{
            display: flex;
            flex-direction: row;
            width: auto;
            @media screen and (max-width: $medium_Device){
                flex-direction: column;
            }
            @media screen and (max-width: $tab_Device){
                flex-direction: column;
            }
            .leftColumn{
                display: flex;
                flex-direction: row;
                width: 50%;
                justify-content: center;
                @media screen and (max-width: $medium_Device){
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: $tab_Device){
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 30px;
                    justify-content: center;
                }
            }
            .leftColumn-fullwidth{
                display: flex;
                flex-direction: column;
                width: 100%;
                @media screen and (max-width: $medium_Device){
                    flex-direction: row;
                    width: 100%;
                }
                @media screen and (max-width: $tab_Device){
                    flex-direction: column;
                    align-items: center;
                }
            }
            .singleColumn{
                flex-direction: column;
            }
            .doubleColumn{
                @media screen and (max-width: $medium_Device){
                    flex-direction: row;
                }
                @media screen and (max-width: $tab_Device){
                    flex-direction: row;
                }
                
            }
            .rightColumn{
                width: 50%;
                justify-content: center;
                @media screen and (max-width: $medium_Device){
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
                @media screen and (max-width: $tab_Device){
                    width: 100%;
                }
            }
            .rightColumn-fullwidth{

            }
            .ThreeInRow{
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                @media screen and (max-width: $medium_Device){
                    flex-direction: row;
                }
                @media screen and (max-width: $tab_Device){
                    flex-direction: column;
                }
                .handiCap-leftcolumn{
                    width: 33.3%; 
                    @media screen and (max-width: $medium_Device){
                        width: 33.3%; 
                    }
                    @media screen and (max-width: $tab_Device){
                        width: 100%;
                    }                 
                
                }
                .handiCap-midcolumn{
                    width: 33.3%; 
                    @media screen and (max-width: $medium_Device){
                        width: 33.3%; 
                    }
                    @media screen and (max-width: $tab_Device){
                        width: 100%;
                    } 
                    
                }
                .handiCap-rightcolumn{
                    width: 33.3%; 
                    @media screen and (max-width: $medium_Device){
                        width: 33.3%; 
                    }
                    @media screen and (max-width: $tab_Device){
                        width: 100%;
                    } 
                    
                }
            }
            
        }
        .fullWidth-column{
            width: 100%;
            @media screen and (max-width: $medium_Device){
                width: 100%;
            }
            @media screen and (max-width: $tab_Device){
                width: 100%;
            }
        }
    .paddingRight {
        padding-right: 60px;
        @media screen and (max-width: $medium_Device){
            padding-right: 0px;
        }
        @media screen and (max-width: $tab_Device){
            padding-right: 0px;
        }
    }
}
.sectionTitle small {
    font-size: 16px;
    font-weight: normal;
}
.tableHeader-home,
.tableHeader-away {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    border-radius: 5px 5px 0 0;
    @media screen and (max-width: $tab_Device){
        padding: 2px 10px;
    }
}
.tableHeader-home .tableHeading,
.tableHeader-away .tableHeading {
    color: #fff;
    font-family: HelveticaCondensed;
    font-weight: 600;
    font-size: 20px;
}
.tableHeader-home .tableHeading > span,
.tableHeader-away .tableHeading > span {
    font-weight: normal;
    font-size: 14px;
    font-family: HelveticaCondensed;
}
.tableHeader-home .tableHeading-rightSection,
.tableHeader-away .tableHeading-rightSection {
    justify-content: flex-end;
}
.tableHeader-home {
    background-color: $homeTeamColor;
}
.tableHeader-away {
    background-color: $awayTeamColor;
}
ul.tabsList {
    padding: 0px 20px;
    // font-size: -0px;
    margin: 0px 0px 0px 0px;
    text-align: center;
    height: 32px;
    line-height: 31px;
    @media screen and (max-width: $tab_Device){
        height: 29px;
    }
    li {
        display: inline;
        margin: 0;
        margin-right: 10px;
        a {
            text-decoration: none;
            position: relative;
            top: -1px;
            padding: 8px 18px;
            cursor: pointer;
            font-size: 15px;
            border-bottom-color: #b7b7b7;
            color: $dark-blue;
            // border-radius: 3px 3px 0 0;
            border-radius: 3px 3px 0 0;
            outline: none;
            background-color: $background-white;
            @media screen and (max-width: $tab_Device){
                padding: 5px 15px;
                font-size: 14px;
            }
        }
        &.active a {
            font-weight: 600;
            background-color: $tertiaryColor;
            color: $white;
            border: 0px solid #ff6f29;
            border-bottom-color: #3f6df5;
            font-family: inherit;
        }
    }
}
.tabSection {
    width: 100%;
    flex-direction: column;
    display: flex;
    // padding-top: 5px;
    position: relative;
    .tabsContent {
        display: flex;
        flex-direction: column;
        background-color: $secondaryColor;
        padding-top: 20px;
        color: #fff;
        @media screen and (max-width: $tab_Device){
            padding-top: 10px;
          }
        .tabContent {
            width: 100%;
            animation: fadein 0.6s;
        }
    }
    .tabTable-content {
        padding: 0px 20px 20px;
        @media screen and (max-width: $tab_Device){
            padding: 0px 10px 20px;
        }
    }
}
.tab {
    cursor: pointer;
    width: 100%;
    background: #fff;
    display: inline-block;
    color: #000;
    font-size: 13px;
}
@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.panel-title {
    font-size: 15px;
    font-weight: 600;
}
.table-fixture {
    display: flex;
    width: 300px;
    font-weight: 700;
    color: #001a69;
    // justify-content: center;
    text-transform: uppercase;
    transform: scale(1, 1.1);
    @media screen and (max-width: $tab_Device){
        width: 180px;
        font-weight: 600;
      }
}
.team-image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
    align-items: center;
    .homeTeam-name,
    .awayTeam-name {
        width: 135px;
        @media screen and (max-width: $medium_Device){
            width: 130px;
            font-size: 12px;
  }
  @media screen and (max-width: $tab_Device){
    width:82px;
    font-size: 11px;
  }
    }
    .homeTeam-name {
        padding-right: 5px;
        text-align: right;
    }
    .awayTeam-name {
        padding-left: 5px;
        text-align: left;
    }
    img {
        width: 25px;
    }
}
.status {
    display: inline-block;
    text-align: center;
    padding: 3px;
    margin: 1px;
    border-radius: 3px;
    color: #fff;
    width: 18px;
    min-width: 18px;
    max-width: 18px;
    line-height: 18px;
    font-size: 11px;
    @media screen and (max-width: $medium_Device){
		width: 15px;
        min-width: 15px;
        max-width: 15px;
        line-height: 15px;
        padding: 1px;
        font-size: 11px;
  }
  @media screen and (max-width: $tab_Device){
    width: 16px;
        min-width: 15px;
        max-width: 15px;
        line-height: 15px;
        font-size: 10px;
  }
    &.win {
        background-color: $win;
    }

    &.loss {
        background-color: $loss;
    }

    &.draw {
        background-color: $draw;
    }

    &.High {
        background-color: $high;
    }

    &.Low {
        background-color: $low;
    }

    &.BTTSno {
        background-color: $BTTSno;
    }

    &.BTTSyes {
        background-color: $BTTSyes;
    }
}
.win-predictor {
    display: inline-flex;
    width: 130px;
    padding: 2px 0;
    justify-content: center;
    @media screen and (max-width: $medium_Device){
		width: 130px;
  }
    @media screen and (max-width: $tab_Device){
    width: 85px;
  }
    .percentage {
        // display: inline-block;
        display: flex;
        justify-content: center;
        padding: 2px 6px;
        // text-align: center;
        color: #fff;
        font-size: 12px;
        @media screen and (max-width: $medium_Device){
            padding: 1px 6px;
        }
        @media screen and (max-width: $tab_Device){
            font-size: 11px;
          }
        &.win {
            background-color: $win;
            border-radius: 15px 0 0 15px;
        }

        &.draw {
            background-color: $draw;
        }

        &.loss {
            background-color: $loss;
            border-radius: 0px 15px 15px 0px;
        }
    }
}
.form-grid {
    display: flex;
    margin: 2px 0;
    width: 100%;
}
.form-col {
    margin: 1px 2px;
    width: 100%;
}
.form-group {
    &.form-inline {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
    }
    &.form-inline-group-staging {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
        position: absolute;
        right: 22px;
        top: 0px;
        @media screen and (max-width: $tab_Device){
            top: 2px;
        }
    }
    &.form-inline-group-staging-league {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
        justify-content: flex-end;
        margin:8px 18px 0 0;
    }
    &.form-inline-fixture-result {
        display: flex;
        flex-direction: row;
        padding-left: 10px;
        position: absolute;
        right: 22px;
        top: 75px;
        @media screen and (max-width: $tab_Device){
            top: 60px;
        }
    }
    .form-label {
        display: flex;
        font-size: 14px;
        align-items: center;
        margin-right: 10px;
        color: #001a69;
        margin-bottom: 2px;
        font-weight: 600;
    }
    .form-control {
        input,
        select {
            height: 30px;
            border: solid 1px #ccc;
            border-radius: 3px;
            font-size: 13px;
        }

        input {
            text-indent: 8px;
            @media screen and (max-width: $tab_Device){
                height: 25px;
            }
        }

        select {
            color: #252525;
            padding: 2px 6px;
            min-width: 58px;
            @media screen and (max-width: $tab_Device){
                height: 28px;
            }
        }
    }
}
.dropdown-position {
    position: absolute;
    right: 20px;
    // top: 6px;
    color: #fff;
}
.white {
    color: #fff !important;
}
.tableTwo-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px 20px;
    @media screen and (max-width: $medium_Device){
        flex-direction: column;
    }
    @media screen and (max-width: $tab_Device){
        padding: 0 10px 20px;
        flex-direction: column;
    }
    &.tableTwo-columntabview{
        flex-direction: row;
        
        .tableHome,
        .tableAway {
            display: flex;
            flex-direction: row;
            width:100%;
            @media screen and (max-width: $medium_Device){
                width: 49.5%;
            }
            @media screen and (max-width: $tab_Device){
                width: 49.5%;
            }
        }
    }
    &.noPadding {
        padding: 0;
    }
    .tableHome,
    .tableAway {
        display: flex;
        flex-direction: row;
        width: 100%;
        @media screen and (max-width: $medium_Device){
            width: 100%;
        }
        @media screen and (max-width: $tab_Device){
            width: 100%;
        }
    }
    .tableHome {
        justify-content: flex-start;
        margin: 0 5px 20px 0;
        @media screen and (max-width: $medium_Device){
            margin-bottom: 20px;
        }
        @media screen and (max-width: $tab_Device){
            margin-bottom: 10px;
        }
    }
    .tableAway {
        justify-content: flex-end;
    }
    .tableFull-width {
        width: 100%;
        @media screen and (max-width: $medium_Device){
            width: 100%;
        }
        @media screen and (max-width: $tab_Device){
            width: 100%;
        }
    }
}
.leagueDropdown {
    width: 100%;
}
.spaced {
    margin-left: 25px;
  margin-right: 25px;
  }
.textIcon {
    display: flex;
    // max-width: 102px;
    max-width: 90px;
    margin: 0 auto;
    // justify-content: space-between;
    justify-content: flex-end;
    .hdcValue{
        text-align: right;
    }
    span {
        padding-right: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center;
    }
}
.textIcon-center{
    justify-content: center;
}
// Seasonal stats common properties
.seasonalStats {
    background-color: $secondaryColor;

    .seasonalStats-container {
        padding: 0px;
    }
    .seasonalStats-content {
        padding: 0 20px 20px;
        @media screen and (max-width: $tab_Device){
            padding: 0 10px 10px;
        }
    }
}
.seasonalStats-tablesList {
    display: flex;
    background-color: #bed2ec;
    padding: 10px 0;
    justify-content: center;
    border-bottom: solid 2px #fff;
    .listItems {
        display: flex;
        flex-direction: row;
        padding: 0px 90px;
        line-height: 25px;
        text-align: center;
        @media screen and (max-width: $tab_Device){
            padding: 0px 30px;
        }
        ul {
            overflow: hidden;
            li {
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                @media screen and (max-width: $tab_Device){
                    font-size: 14px;
                }
                a {
                    display: inline;
                    text-align: center;
                    padding: 6px;
                    text-decoration: none;
                    color: $black;
                }
                &:hover a {
                    color: #272727;
                }
                &.active a {
                    color: $tertiaryColor;
                }
                &:not(:last-child):after {
                    content: "|";
                    color: #000;
                }
            }
        }
    }
}
.sectionHeading {
    background-color: #4b6e9f;
    padding: 10px 20px;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $tab_Device){
        padding: 5px 10px;
        height: 26px;
    }
    .sectionTitle {
        font-family: HelveticaCondensed;
        font-size: 22px;
        font-weight: 600;
        @media screen and (max-width: $tab_Device){
            font-size: 20px;
        }
        // text-transform: uppercase;
        span {
            text-transform: none;
        }
    }
    .sectionFilter {
        display: flex;
        align-items: center;
       
    }
    
    .form-label {
        color: #fff;
    }
}
.extraLarge {
    font-size: 40px;
}
.large {
    font-size: 25px;
    font-weight: 600;
}
.medium {
    font-size: 20px;
}
.small {
    font-size: 15px;
}
.extraSmall {
    font-size: 12px;
}
.formStatus {
    width: 200px;
}
.formGroup-container {
    display: flex;
}
.homeBg {
    background-color: $homeTeamColor;
}
.awayBg {
    background-color: $awayTeamColor;
}
.neutral {
    background-color: #ff6f29;
}
.teamSelected {
    color: #fff;
    font-weight: 600;
}
// head-to-head-hkjc-mode
.headTo-headHKJC {
    padding-top: 20px;
    background-color: #ddd;
}
.headTo-headHKJC-statistics {
    padding-top: 20px;
    background-color: $secondaryColor;
}
.recentForm-info {
    text-align: center;
    margin: 5px;
    .selectedData {
        border: solid 1px #ddd;
        width: 60%;
        padding: 5px;
        border-radius: 5px;
        line-height: 30px;
        margin: 5px auto;
        .fixture {
            font-weight: 600;
            .score {
                background-color: #001a69;
                border:solid 1px #133667;
                color: #fff;
                text-align: center;
                padding: 3px 10px;
                border-radius: 4px;
                letter-spacing: 2px;
            }
        }
    }
}
.hiloGraphtables {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .heading {
        margin: 20px 0;
    }
}
.hiLoGraph {
    text-align: center;
}
.hiLoGraph-header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 10px;
    min-height: 20px;
    .line-title {
        width: 100px;
        justify-content: center;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
    }
    .mainTitle {
        font-size: 20px;
        font-weight: 600;
        text-transform: none;
        @media screen and (max-width: $tab_Device){
            font-size: 18px;
        }
        small {
            font-weight: normal;
        }
    }
    .legend-info {
        display: flex;
        align-items: center;
        font-size: 13px;
        border-radius: 15px;
        font-family: "Montserrat", sans-serif;
        /* border: solid 1px #5901c4; */
        padding: 0 5px;
        .homeLegend,
        .awayLegend {
            width: 20px;
            height: 20px;
            margin: 0 5px;
            border-radius: 4px;
            text-align: center;
        }
        .homeLegend {
            background-color: #74aa8c;
        }
        .awayLegend {
            background-color: #ebbf32;
        }
    }
}
.barGraph,
.hiLoGraph-body {
    display: flex;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
}
.lineContent {
    // border: solid 1px #405f85;
    border-radius: 4px;
    padding: 0 20px;
    height: 100%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    line-height: 43px;
    margin: 0 20px;
}
.barsContent {
    text-align: center;
}
.hiLoBar {
    width: 400px;
    height: 24px;
    display: flex;
    align-items: stretch;
    border-radius: 12px;
    overflow: hidden;
    margin: 20px 0;
    align-items: center;
    line-height: 25px;
    @media screen and (max-width: $medium_Device){
        width: 440px;
      }
    @media screen and (max-width: $tab_Device){
        width: 285px;
        line-height: 20px;
    }
    .homeBar {
        background-color: #ebbf32;
        // flex: var(--blue);
        color: #000;
    }
    .awayBar {
        background-color: #74aa8c;
        // flex: var(--red);
    }
}
.bar > * {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sectionMain-title {
    display: flex;
    justify-content: space-between;
    width: 49.5%;
    align-items: center;
}
.internalGraph {
    display: flex;
    justify-content: space-between;
    color: #fff;
}
.graphGrid {
    margin-top: 0px;
}
.pastSeasons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 25px 20px;
    line-height: 30px;
    .pastSeasons-block {
        border: solid 1px #4b6e9f;
        border-radius: 4px;
        width: 25%;
        padding: 20px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .homeWins {
        background-color: #a9edf5;
    }
    .draws {
        background-color: #f2f2f2;
    }
    .awayWins {
        background-color: #f3c7c7;
    }
}
.pastSeasons-grid {
    background-color: #fff;
    margin: 0;
    padding: 10px 0;
    border-bottom: solid 1px #eee;
    .small {
        padding: 5px 10px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        @media screen and (max-width: $tab_Device){
            padding: 1px 20px;
            font-size: 13px;
          }
    }
}
.dualHilo-graph {
    display: flex;
    margin: 0 65px;
    @media screen and (max-width: $medium_Device){
        margin: 0px auto;
      }
    @media screen and (max-width: $tab_Device){
        margin: 0px auto;
    }
    .middleLine {
        text-align: center;
        margin-bottom: 23px;
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        min-height: 20px;
    }
}
sup {
    top: 0;
}
.teamSelection {
    display: flex;
    justify-content: center;
    .teamSelection-heading {
        display: flex;
        width: 350px;
        background-color: #fff;
        border-radius: 4px 4px 0 0;
        margin-top: 10px;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 15px;
        border-bottom: 1px solid #536697;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        color: #17396b;
    }
}
.th-separator {
    border-right: solid 1px #314977;
}
.th-spaceSeparator {
    border-right: solid 8px #292e3b;
}
.th-separator2 {
    border-right: solid 1px #bbb;
}
.th-spaceSeparator2 {
    border-right: solid 8px #ddd;
}
hr {
    border: solid 1px #49679e;
}
.fullPage {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.lft_sidebar {
    display: flex;
    flex-direction: column;
    align-items: justify;
    width: 85%;
    line-height: 30px;
    background-color: #f5f7ff;
}
.rft_sidebar {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 15%;
}
.poweredBy {
    display: flex;
    background-color: #efefef;
    align-items: center;
    .poweredBy-logo img {
        max-width: 200px;
        margin-right: 10px;
    }
    .poweredBy-content {
        font-size: 9px;
        line-height: 1.5em;
        padding: 20px;
        @media screen and (max-width: $tab_Device){
            padding: 10px;
          }
    }
}
/* loading css */
.noData {
    display: flex;
    line-height: 40px;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
}
.noDatagraph {
    display: flex;
    line-height: 40px;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 12px;
    padding-left: 20px;
}
.loader {
    margin: 10px auto;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid blue;
    border-bottom: 5px solid blue;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.gameSearch {
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #fff;
    margin: 0 auto;
    padding: 0px 15px;
    @media screen and (max-width: $tab_Device){
        padding: 0px 10px;
    }

    .form-grid {
        width: 45%;
    }
}
.teamSelection-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
}
.fadeout {
    color: #bdbfbe;
}

/* inner tabs  */
.tab {
    cursor: pointer;
    padding: 5px 25px;
    margin: 0px 0px 10px 0px;
    background: #fff;
    display: inline-block;
    color: $secondaryColor;
    border-radius: 3px 0px 0px 3px;
    border: solid 1px #001a69;
    font-size: 13px;
}
.HKJC-panels {
    background: #ffffff;
    width: 100%;
    overflow: hidden;
    padding: 5px 0;
}
.HKJC-panel {
    display: none;
    animation: fadein 0.8s;
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.HKJC-panel-title {
    font-size: 15px;
    font-weight: 600;
}
.HKJC-radio {
    display: none;
}
.HKJC-label1 {
    margin-left: 180px;
}
.HKJC-label2 {
    position: relative;
    left: -4px;
    border-radius: 0px;
    border-left: none;
    border-right: none;
}
.HKJC-label3 {
    position: relative;
    left: -8px;
    border-radius: 0px;
    border-left: none;
    border-right: none;
}
.HKJC-label4 {
    position: relative;
    left: -12px;
    border-radius: 0px 3px 3px 0px;
    border-left: none;
}
#one:checked ~ .HKJC-panels #one-panel,
#two:checked ~ .HKJC-panels #two-panel,
#three:checked ~ .HKJC-panels #three-panel,
#four:checked ~ .HKJC-panels #four-panel,
#five:checked ~ .HKJC-panels #five-panel,
#six:checked ~ .HKJC-panels #six-panel,
#seven:checked ~ .HKJC-panels #seven-panel,
#eight:checked ~ .HKJC-panels #eight-panel {
    display: block;
}
#one:checked ~ .HKJC-tabs #one-tab,
#two:checked ~ .HKJC-tabs #two-tab,
#three:checked ~ .HKJC-tabs #three-tab,
#four:checked ~ .HKJC-tabs #four-tab,
#five:checked ~ .HKJC-tabs #five-tab,
#six:checked ~ .HKJC-tabs #six-tab,
#seven:checked ~ .HKJC-tabs #seven-tab,
#eight:checked ~ .HKJC-tabs #eight-tab {
    background: $secondaryColor;
    color: #fff;
}

//expand and collapse CSS

  .collapsible {
    // background-color: #777;
    color: #fff;
    cursor: pointer;
    // padding: 18px;
    // width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 40px;
    margin: 40px 20px 0 0;
  }

//   .collactive, .collapsible:hover {
//     background-color: #555;
//   }

  .collapsible:after {
    content: '\002B';
    color: #fff;
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }

  .collactive:after {
    content: "\2212";
  }

  .content {
    padding: 0 18px;
    // max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
   
  }

// Standing plaoffs
.playOffs {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 700;
    .playOffs-fixture {
        display: flex;
        text-transform: uppercase;
        transform: scale(1, 1.1);
        margin: 0 auto;
        width: 100%;
    }

    .playOffs-fixture-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 45%;
        align-items: center;
        padding: 5px;
        border: solid 1px #ddd;
        border-radius: 3px;
        background-color: #eee;
        .homeTeam-name,
        .awayTeam-name {
            width: 85%;
        }
        .homeTeam-name {
            padding-right: 5px;
            text-align: right;
        }
        .awayTeam-name {
            padding-left: 5px;
            text-align: left;
        }
        img {
            height: 25px;
            width: auto;
        }
    }
    .playOffs-team-vs,
    .playOffs-team-score {
        display: flex;
        align-items: center;
        color: #f78a0a;
        padding: 0 5px;
        text-transform: none;
        width: 10%;
        justify-content: center;
    }
}

// Tournament Bracket
.tournament-container{
    width: auto;
    background-color: #eee;
    .tournament-container1{
        width: 25%;
        margin: 0 auto;
    }
    .tournament-container3{
        width: 60%;
        margin: 0 auto;
    }
}

.tournament-headers {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	border-bottom: 1px solid #ccc;
    border-top: 1px solid #133667;
    background-color:$primaryColor;
    color: #fff;
	h3 {
		width: 25%;
		text-align: center;
		font-weight: 400;
		// border-right: 1px solid #133667;
		margin: 0;
		padding: 1rem;
       
        @media screen and (max-width: $tab_Device){
            font-size: 14px;
        }
	}
}
.tournament-brackets {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	background: #fdfdfd;
	margin-bottom: 50px;
    min-height: 628px;
}
.bracket {
	padding-left: 0;
	display: flex;
	margin: 0;
	padding: 30px 0;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-evenly;
	list-style-type: none;
    // max-width: 35%;
    margin: 0 auto;
	// border-right: 1px dashed #ccc;
	flex: 1;
	&:first-of-type {
		.team-item {
			&:before {
				display: none;
			}
		}
	}
	&:last-of-type {
		.team-item {
			&:after {
				display: none;
			}
		}
	}
}
.team-item {
	background-color: #f4f4f4;
    color: #000;
	padding: 0.5rem 0.3rem;
	display: block;
	margin: 0.5rem 10px;
	position: relative;
	// vertical-align: middle;
	line-height: 2;
	text-align: center;
    border: solid 1px #133667;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    line-height: 18px;
    align-items: center;
    @media screen and (max-width: $tab_Device){
        flex-direction: column;
    }
    .finalMatch{
        width: 100%;
        flex-direction: row;
        line-height: 25px;
        .matchTime{
            text-align: center;
            width: 100%;
            margin-left: 20px;
            
        }
    }
    .countryLogo-small{
        width: 20px !important;
        float: left;
        // margin-top: 7px;
        right: 10px;
        padding-left: 0;
    }
    &.team-item-right {
        display: flex;
    justify-content: space-between;
    line-height: 18px;
    align-items: center;
    }
	&:after {
		content: "";
		border-color: #082a8e;
		border-width: 1px;
		position: absolute;
		display: block;
		width: 10px;
		right: -11px;
	}
	&:nth-of-type(odd) {
		&:after {
			border-right-style: solid;
			border-top-style: solid;
			height: 140%;
			top: 50%;
		}
	}
	&:nth-of-type(even) {
		&:after {
			border-right-style: solid;
			border-bottom-style: solid;
			height: 140%;
			top: -88%;
		}
	}
	&:before {
		content: "";
		border-top: 1px solid #082a8e;
		position: absolute;
		height: 2px;
		width: 10px;
		left: -11px;
		top: 50%;
        // top: -3%;
        //     width: 0;
        //     height: 0;
        //     border-top: 23px solid transparent;
        //     border-left: 10px solid #082a8e;
        //     border-bottom: 23px solid transparent;
	}
	time {
		display: inline-block;
		background-color: #dbdbdb;
		font-size: 0.8rem;
		padding: 0 0.6rem;
	}
    // .countryName{
    //    
    // }
}
.countryLogo-small{
        width: 20px !important;
        float: left;
        margin-top: 5px;
    }
    .score{
        color: #000;
        float: right;
        font-size: 16px;
        font-weight: 600;
        padding: 1px;
        height: 24px;
        display: flex;
        width: 25px;
        justify-content: center;
        align-items: center;
    }
.KickOf-time{
    position: relative;
    margin: 0 auto;
    color: red;
    height: 20px;
    text-align: center;
}
.team-item-logo{
    position: relative;
    // top:25px;
    text-align: center;
}
.team-item-result{
    padding: 5px;
	// position: relative;
    position: absolute;
    top: 20%;
    left: 5px;
    width: calc(100% - 22px);
    text-align: center;
    font-size: 13px;
    border: solid 1px #082a8e;
    color: #000;
    height: auto;
    background-color: #eee;
    time {
		display: inline-block;
		background-color: #dbdbdb;
        margin-left: 20px;
		font-size: 0.8rem;
		padding: 0 0.6rem;
	}
    .team-item-resultContent{
        padding: 0.5rem 0.3rem;
    }
    &.result-info{
        top: 70%;
    }
    .teamCup{
        position: absolute;
        top: -38px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}
.team-item-result1{
    position: absolute;
    text-align: center;
    color: #000;
    margin: 0 auto;
    padding: 5px;
}

.bracket.right {
	.team-item {
        .countryLogo-small{
            width: 20px !important;
            float: right;
            // margin-top: 7px;
        }
        .score{
            color: #000;
            float: left;
            font-size: 16px;
            font-weight: 600;
            // border-left: unset;
            padding: 1px;
            height: 25px;
            display: flex;
            width: 25px;
            justify-content: center;
            align-items: center;
        }
        .tree-arrow {
            width: 0;
            height: 0;
            border-top: 23px solid transparent;
            border-bottom: 23px solid transparent;
            border-right: 8px solid #082a8e;
            border-left: 0;
            position: absolute;
            left: -8px;
            // top: -1px;
            top: 50%;
            transform: translate(0, -50%);
        }
		&:after {
			content: "";
			border-width: 0;
			position: unset;
			display: unset;
			width: unset;
			right: unset;
			content: "";
			border-top: 1px solid #082a8e;
            top: 50%;
			position: absolute;
			height: 2px;
			width: 10px;
			right: -11px;
            // top: 50%;
			// top: -3%;
            // width: 0;
            // height: 0;
            // border-top: 23px solid transparent;
            // border-right: 10px solid #082a8e;
            // border-bottom: 23px solid transparent;
		}
		&::before {
			content: "";
			border-color: #082a8e;
			border-width: 1px;
			position: absolute;
			display: block;
			width: 10px;
			left: -11px;
		}
		&:nth-of-type(odd) {
			&::before {
				border-left-style: solid;
				border-top-style: solid;
				height: 140%;
				top: 50%;
			}
        }
        
		&:nth-of-type(even) {
			&::before {
				border-left-style: solid;
				border-bottom-style: solid;
				height: 140%;
				top: -90%;
                // for arrow
				border-top: 0;
			}
		}
	}
    
}
.bracket-2 {
background-color: #e6f2ff !important;
	.team-item {
		&:nth-of-type(odd) {
			&:after {
				height: 277%;
				top: 50%;
			}
		}
		&:nth-of-type(even) {
			&:after {
				height: 277%;
				top: -224%;
			}
		}
	}
}
.bracket-6 {
background-color: #e6f2ff !important;
	.team-item {
		&:nth-of-type(odd) {
			&::before {
				height: 268% !important;
				top: 50%;
			}
        }
        &:nth-of-type(even) {
			&::before {
				height: 268% !important;
				top: -218% !important;
			}
		}
	}
}
.bracket-3 {
	.team-item {
		&:nth-of-type(odd) {
			&:after {
				height: 563%;
				top: 50%;
			}
		}
		&:nth-of-type(even) {
			&:after {
				height: 563%;
				top: -512%;
			}
		}
	}
}
.bracket-5 {
	.team-item {
		&:nth-of-type(odd) {
			&::before {
				height: 563% !important;
				top: 50%;
			}
		}
		&:nth-of-type(even) {
			&:before {
				height: 563% !important;
				top: -513% !important;
			}
		}
	}
}
.bracket-4 {
    background-color: #e6f2ff !important;
    position: relative;
	.team-item {
		&:nth-of-type(odd) {
			&:after {
				height: 700%;
				// top: 50%;
                // for arrow
                top:50%;
			}
		}
		&:nth-of-type(even) {
			&:after {
				height: 700%;
				// top: 50%;
                // top: -650%;
                // for arrow
                top:50%;
			}
		}
        &:nth-of-type(even) {
			&::before {
				top:50%;
			}
		}
		&:after {
			height: 0% !important;
            // for arrow
            // width: 0;
            // height: 0;
            // border-top: 23px solid transparent;
            // border-right: 10px solid #082a8e;
            // border-bottom: 23px solid transparent;
		}
    }
    
}


.bracket:last-of-type {
    
    .team-item:after {
        display: none;
    }
}


.tree-arrow{
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 8px solid #082a8e;
    position: absolute;
    right: -8px;
    // top: -1px;
    top: 50%;
    transform: translate(0, -50%);
}

// .react-tiny-popover-container{
//     width: 50% !important;
//     &:hover {
//         width: 100% !important;
//         z-index: 50;
//       }
// }
// bracket 3//

.tournament-containerVertical{
    .tournament-container {}

.tournament-headers {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 50px;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #133667;
  background-color: #001a69;
  align-items: center;
  color: #fff;
  font-weight: 600;
  :last-child{
    border-right: none;
  }
  
  h3 {
    width: 25%;
    text-align:center;
    font-weight: 400;
    border-right: 1px solid #133667;
    margin: 0;
    padding:1.2rem;
  }
}

.tournament-brackets {
  display: flex;
  flex-direction: row; 
  list-style-type: none;
  background: #fdfdfd;
//   margin-bottom: 50px;
}

.bracket {
  padding-left: 0;
  display: flex;
  margin: 0;
  padding: 15px 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  list-style-type: none;
  // border-right: 1px dashed #ccc;
  flex: 1;
}

.team-item {
  background-color: #f5f5f5;
  padding: .5rem .5rem;
  display: block;
  margin: 20px 15px;
  position: relative;
  line-height: 3;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-content: center;
  border: solid 2px #ccc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  align-items: normal;
  // box-shadow: 1px 1px #ddd;
  span{
      display: flex;
      align-items: center;
  }
  img{
    width: 25px;
    margin: 5px 10px 5px 10px;
    border: solid 1px #ddd;
    padding: 1px;
    border-radius: 4px;
  }
}

.team-item:after {
  content:'';
  border-color: #ccc;
  border-width: 2px;
  position: absolute;
  display: block;
  width: 15px;
  right: -17px;
}

.team-item:nth-of-type(odd):after {
  border-right-style: solid;
  border-top-style: solid;
  height: 100%;
  top: 50%;
}

.team-item:nth-of-type(even):after {
  border-right-style: solid;
  border-bottom-style: solid;
  height: 100%;
  top: -50%;
}

.team-item:before {
  content:'';
  border-top: 2px solid #ccc;
  position: absolute;
  height: 2px;
  width: 15px;
  left: -17px;
  top: 50%;
}
.bracket-1 {
background-color: #f7f7f7;
}
.bracket-2 {
  .team-item:nth-of-type(odd):after {
    height: 200%;
    top: 50%;
  }
  .team-item:nth-of-type(even):after {
    height: 200%;
    top: -150%;
  }
}

.bracket-3 {
    background-color: #f7f7f7;
  .team-item:nth-of-type(odd):after {
    height: 350%;
    top: 50%;
  }
  .team-item:nth-of-type(even):after {
    height: 350%;
    top: -300%;
  }
}

.bracket-4 {
  .team-item:nth-of-type(odd):after {
    height: 700%;
    top: 50%;
  }
  .team-item:nth-of-type(even):after {
    height: 700%;
    top: -650%;
  }
}
.bracket-5 {
    background-color: #f7f7f7;
    }
.bracket:first-of-type {
  .team-item:before {
    display: none;
  }
}

.bracket-4 {
  .team-item:after {
    display: none;
  }
}

.bracket:last-of-type {
//   .team-item:before,
  .team-item:after {
    display: none;
  }
}

.team-item time {
  display: inline-block;
  background-color: #f5f5f5;
  font-size: .8rem;
  // padding: 0 .6rem;
}

}