.layout-header {
	.mainHeader-content {
		height: 90px;
		display: flex;
		justify-content: space-between;
		background-color: $primaryColor;
		@media screen and (max-width: $tab_Device){
			height: 80px;
		}
	}
	.mainHeader-left {
		display: flex;
	}
	.mainHeader-right {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.mainHeader-logo {
		display: flex;
		padding: 0 25px 0px 10px;
		align-items: center;
		img {
			width: 180px;
		}
	}
	.mainHeader-tabs {
		display: flex;
		align-items: flex-end;
		ul {
			display: flex;
			margin-left: 10px;
			li {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 45px;
				min-width: 100px;
				color: $white;
				border-radius: 4px 4px 0 0;
				padding: 0 10px;
				margin: 0 5px;
				font-family: HelveticaCondensed;
				font-size: 18px;
				text-transform: uppercase;
				@media screen and (max-width: $tab_Device){
					font-size: 16px;
					height: 35px;
				  }
				a {
					display: flex;
					color: inherit;
					align-items: center;
					justify-content: center;
					height: 45px;
					min-width: 100px;
					border-radius: 4px 4px 0 0;
					padding: 0 15px;
					margin: 0 5px;
					@media screen and (max-width: $tab_Device){
						padding: 0 10px;
						margin: 0 3px;
						height: 35px;
					  }
					&:hover {
						background-color: #1d3376;
						color: $white;
					}
					&.active {
						background-color: $background-white;
						color: $dark-blue;
						font-weight: bold;

						&:hover {
							background-color: $white;
							color: #000;
							font-weight: bold;
						}
					}
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
.subHeader {
	height: 50px;
	display: flex;
	align-items: center;
	background-color: $background-white;
	justify-content: space-between;
	font-family: HelveticaCondensed;
	font-weight: bold;
	padding: 0 10px;
	ul li {
		display: inline-block;
		color: $dark-blue;
		text-align: center;
		padding: 2px 0px;
		text-decoration: none;
		font-size: 16px;
		border-radius: 4px;
		margin: 0 10px;
		height: 34px;
		line-height: 36px;
		text-transform: uppercase;
		@media screen and (max-width: $medium_Device){
			margin: 0 8px;
		}
		@media screen and (max-width: $tab_Device){
			font-size: 14px;
			margin: 0 5px;
			height: 30px;
		line-height: 30px;
		  }
		a {
			color: inherit;
			padding: 10px 20px;
			cursor: pointer;
			letter-spacing: 1px;
			@media screen and (max-width: $tab_Device){
				padding: 8px;
			  }
		}
		&:hover {
			background-color: #d7dce9;
			color: black;
		}
		&.active {
			background-color: $tertiaryColor;
			color: $white;
		}
	}
	.changeMode li {
		// border: 1px solid #001a69;
		background-color: #001a69;
		color: #fff;
		font-weight: 700;
		height: 32px;
		line-height: 34px;
		a {
			padding: 10px 10px;
			font-size: 19px;
		}
	}
	a.a_mode:hover {
		background-color: red;
		background-color: #001a69;
		color: #fff !important;
		border-radius: 5px;
		padding: 8px 10px 6px 10px !important;
	}
}
.mainTeamBanner .gameDetails {
	display: flex;
	flex-direction: row;
	border-top: solid 1px #fff;
	background-color: $white;
	position: relative;
	height: 120px;
	.homeTeam,
	.awayTeam {
		display: flex;
		width: 50%;
		padding: 5px 0;
	}
	.homeTeam {
		background-color: #0c5483;
	}
	.homeTeam-logo,
	.awayTeam-logo {
		display: flex;
		align-items: center;
		padding: 0px 15px 0 15px;
		@media screen and (max-width: $tab_Device){
			padding: 0px 5px 0 5px;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	.homeTeam-details,
	.awayTeam-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.homeTeam-details .teamName,
	.awayTeam-details .teamName {
		display: flex;
		align-items: center;
		font-size: 30px;
		// font-weight: 600;
		color: #fff;
		text-transform: uppercase;
		height: 60px;
		font-family: HelveticaCondensed;
		max-width: 400px;
		line-height: 31px;
		@media screen and (max-width: $medium_Device){
			font-size: 27px;
			line-height: 28px;
			width: 275px;
		  }
		@media screen and (max-width: $tab_Device){
			font-size: 20px;
			max-width: 205px;
		}
		a{color: #fff;}
	}
	.hometeam_pos {
		display: flex;
		width: 100%;
		justify-content: flex-start;
	}
	.awayteam_pos {
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}
	.homeTeam-details .teamPosition,
	.awayTeam-details .teamPosition {
		display: flex;
		flex-direction: row;
		font-size: 25px;
		color: #fff;
		padding-top: 5px;
		@media screen and (max-width: $tab_Device){
			font-size: 20px;
		}
	}
	.awayTeam {
		justify-content: flex-end;
		background-color: #670633;
	}
}
.leagueDetails {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 250px;
	padding-top: 4px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-wrap: nowrap;
	@media screen and (max-width: $tab_Device){
		width: 185px;
	}
	.kickOff-details {
		background-color: #fff;
		// -webkit-transform: skew(-15deg);
		// -moz-transform: skew(-15deg);
		// transform: skew(-15deg);
		padding: 3px 5px;
		font-size: 13px;
		font-weight: 600;
		min-width: 200px;
		letter-spacing: 1px;
		color: #333;
		@media screen and (max-width: $tab_Device){
			min-width: 150px;
			font-size: 12px;
		}
		.normalPosition {
			// -webkit-transform: skew(15deg);
			// -moz-transform: skew(15deg);
			// transform: skew(15deg);
			display: flex;
			gap: 15px;
		}
		.date {
			font-size: 12px;
			@media screen and (max-width: $tab_Device){
				font-size: 11px;
			}
		}
		.time {
			padding: 0 10px;
		}
	}
	.oddsDetails {
		display: flex;
		// margin-top: 3px;
		margin-top: 15px;
		gap: 8px;

		.odds {
			background-color: #09b3c6;
			padding: 12px 12px;
			text-align: center;
			width: 55px;
			// font-size: 15px;
			// font-weight: 600;
			font-size: 18px;
			font-weight: 700;
			@media screen and (max-width: $tab_Device){
				width: 40px;
			    font-size: 12px;
			}
			&.home {
				background-color: $homeTeamColor;
				color: #fff;
			}

			&.draw {
				background-color: $drawTeamColor;
				color: #000;
			}

			&.away {
				background-color: $awayTeamColor;
				color: #fff;
			}
		}
	}
}
.lan {
	display: flex;
	color: #fff;
	justify-content: space-around;
	margin-right: 15px;
	align-items: center;
	a {
		color: #fff;
		font-size: 12px;
		margin: 0 5px;
	}
	svg{width: 25px;
	}
}
.teamLogo-img{
	width: 90px !important;
	height: auto;
	@media screen and (max-width: $tab_Device){
		width: 80px !important;
	  }
}
.playerImg{
	width: 150px;
	height: auto;
}