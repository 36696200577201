.layout-footer {
	.footerBottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #a3a2a2;
		justify-content: center;
		padding-top: 10px;
		background-color: $background-black;
	}
	.footerLinks ul li {
		display: inline-block;
		a {
			color: #ddd;
			font-size: 12px;
			padding: 10px;

			&:hover {
				color: #fff;
			}
		}
		&:not(:last-child):after {
			content: "|";
		}
	}
	.footer-copyRight {
		color: #ddd;
		font-size: 11px;
		padding: 10px 0;
	}
}
