.country-flag{
    //max-width: 100%; 
    //background-size: 100%;
    background-image: url("#{$flag-path}/country-flags.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
}
// .BLM-sideMenu{
//     .BLM-flag{
//         margin-right: 5px;
//     }
// }
//.country-flag { background: url('country-flag.png') no-repeat top left; width: 30px; height: 30px;  } 
.country-flag.0 { background-position: 0 0; } 
.country-flag.abkhazia { background-position: 0 -30px; } 
.country-flag.afghanistan { background-position: 0 -60px; } 
.country-flag.aland-islands { background-position: 0 -90px; } 
.country-flag.albania { background-position: 0 -120px; } 
.country-flag.andorra { background-position: 0 -150px; } 
.country-flag.angola { background-position: 0 -180px; } 
.country-flag.antigua-and-barbuda { background-position: 0 -210px; } 
.country-flag.anguilla { background-position: 0 -240px; } 
.country-flag.armenia { background-position: 0 -270px; } 
.country-flag.argentina { background-position: 0 -300px; } 
.country-flag.aruba { background-position: 0 -330px; } 
.country-flag.australia { background-position: 0 -360px; } 
.country-flag.azerbaijan { background-position: 0 -390px; } 
.country-flag.austria { background-position: 0 -420px; } 
.country-flag.azores-islands { background-position: 0 -450px; } 
.country-flag.bahamas { background-position: 0 -480px; } 
.country-flag.bahrain { background-position: 0 -510px; } 
.country-flag.balearic-islands { background-position: 0 -540px; } 
.country-flag.bangladesh { background-position: 0 -570px; } 
.country-flag.barbados { background-position: 0 -600px; } 
.country-flag.basque-country { background-position: 0 -630px; } 
.country-flag.belarus { background-position: 0 -660px; } 
.country-flag.belgium { background-position: 0 -690px; } 
.country-flag.belize { background-position: 0 -720px; } 
.country-flag.benin { background-position: 0 -750px; } 
.country-flag.bermuda { background-position: 0 -780px; } 
.country-flag.bhutan { background-position: 0 -810px; } 
.country-flag.bhutan-1 { background-position: 0 -840px; } 
.country-flag.bolivia { background-position: 0 -870px; } 
.country-flag.bonaire { background-position: 0 -900px; } 
.country-flag.bosnia-and-herzegovina { background-position: 0 -930px; } 
.country-flag.botswana { background-position: 0 -960px; } 
.country-flag.brazil { background-position: 0 -990px; } 
.country-flag.british-columbia { background-position: 0 -1020px; } 
.country-flag.british-virgin-islands { background-position: 0 -1050px; } 
.country-flag.british-indian-ocean-territory { background-position: 0 -1080px; } 
.country-flag.brunei { background-position: 0 -1110px; } 
.country-flag.bulgaria { background-position: 0 -1140px; } 
.country-flag.christmas-island { background-position: 0 -1170px; } 
.country-flag.canary-islands { background-position: 0 -1200px; } 
.country-flag.burkina-faso { background-position: 0 -1230px; } 
.country-flag.cape-verde { background-position: 0 -1260px; } 
.country-flag.burundi { background-position: 0 -1290px; } 
.country-flag.cambodia { background-position: 0 -1320px; } 
.country-flag.cameroon { background-position: 0 -1350px; } 
.country-flag.china { background-position: 0 -1380px; } 
.country-flag.cayman-islands { background-position: 0 -1410px; } 
.country-flag.chad { background-position: 0 -1440px; } 
.country-flag.ceuta { background-position: 0 -1470px; } 
.country-flag.chile { background-position: 0 -1500px; } 
.country-flag.canada { background-position: 0 -1530px; } 
.country-flag.colombia { background-position: 0 -1560px; } 
.country-flag.central-african-republic { background-position: 0 -1590px; } 
.country-flag.cocos-island { background-position: 0 -1620px; } 
.country-flag.comoros { background-position: 0 -1650px; } 
.country-flag.corsica { background-position: 0 -1680px; } 
.country-flag.costa-rica { background-position: 0 -1710px; } 
.country-flag.croatia { background-position: 0 -1740px; } 
.country-flag.curacao { background-position: 0 -1770px; } 
.country-flag.cook-islands { background-position: 0 -1800px; } 
.country-flag.cyprus { background-position: 0 -1830px; } 
.country-flag.cuba { background-position: 0 -1860px; } 
.country-flag.czech-republic { background-position: 0 -1890px; } 
.country-flag.denmark { background-position: 0 -1920px; } 
.country-flag.democratic-republic-of-congo { background-position: 0 -1950px; } 
.country-flag.dominica { background-position: 0 -1980px; } 
.country-flag.dominican-republic { background-position: 0 -2010px; } 
.country-flag.egypt { background-position: 0 -2040px; } 
.country-flag.ecuador { background-position: 0 -2070px; } 
.country-flag.east-timor { background-position: 0 -2100px; } 
.country-flag.equatorial-guinea { background-position: 0 -2130px; } 
.country-flag.eritrea { background-position: 0 -2160px; } 
.country-flag.ethiopia { background-position: 0 -2190px; } 
.country-flag.estonia { background-position: 0 -2220px; } 
.country-flag.falkland-islands { background-position: 0 -2250px; } 
.country-flag.fiji { background-position: 0 -2280px; } 
.country-flag.finland { background-position: 0 -2310px; } 
.country-flag.france { background-position: 0 -2340px; } 
.country-flag.french-polynesia { background-position: 0 -2370px; } 
.country-flag.galapagos-islands { background-position: 0 -2400px; } 
.country-flag.gambia { background-position: 0 -2430px; } 
.country-flag.gabon { background-position: 0 -2460px; } 
.country-flag.gibraltar { background-position: 0 -2490px; } 
.country-flag.djibouti { background-position: 0 -2520px; } 
.country-flag.faroe-islands { background-position: 0 -2550px; } 
.country-flag.guatemala { background-position: 0 -2580px; } 
.country-flag.georgia { background-position: 0 -2610px; } 
.country-flag.haiti { background-position: 0 -2640px; } 
.country-flag.guinea-bissau { background-position: 0 -2670px; } 
.country-flag.guinea { background-position: 0 -2700px; } 
.country-flag.guernsey { background-position: 0 -2730px; } 
.country-flag.greenland { background-position: 0 -2760px; } 
.country-flag.guam { background-position: 0 -2790px; } 
.country-flag.grenada { background-position: 0 -2820px; } 
.country-flag.greece { background-position: 0 -2850px; } 
.country-flag.england { background-position: 0 -2880px; } 
.country-flag.germany { background-position: 0 -2910px; } 
.country-flag.ghana { background-position: 0 -2940px; } 
.country-flag.european-union { background-position: 0 -2970px; } 
.country-flag.guyana { background-position: 0 -3000px; } 
.country-flag.hawaii { background-position: 0 -3030px; } 
.country-flag.honduras { background-position: 0 -3060px; } 
.country-flag.hong-kong { background-position: 0 -3090px; } 
.country-flag.hungary { background-position: 0 -3120px; } 
.country-flag.iceland { background-position: 0 -3150px; } 
.country-flag.india { background-position: 0 -3180px; } 
.country-flag.indonesia { background-position: 0 -3210px; } 
.country-flag.iran { background-position: 0 -3240px; } 
.country-flag.iraq { background-position: 0 -3270px; } 
.country-flag.ireland { background-position: 0 -3300px; } 
.country-flag.isle-of-man { background-position: 0 -3330px; } 
.country-flag.israel { background-position: 0 -3360px; } 
.country-flag.italy { background-position: 0 -3390px; } 
.country-flag.ivory-coast { background-position: 0 -3420px; } 
.country-flag.jamaica { background-position: 0 -3450px; } 
.country-flag.japan { background-position: 0 -3480px; } 
.country-flag.jersey { background-position: 0 -3510px; } 
.country-flag.jordan { background-position: 0 -3540px; } 
.country-flag.kazakhstan { background-position: 0 -3570px; } 
.country-flag.kenya { background-position: 0 -3600px; } 
.country-flag.kiribati { background-position: 0 -3630px; } 
.country-flag.kosovo { background-position: 0 -3660px; } 
.country-flag.kuwait { background-position: 0 -3690px; } 
.country-flag.kyrgyzstan { background-position: 0 -3720px; } 
.country-flag.laos { background-position: 0 -3750px; } 
.country-flag.latvia { background-position: 0 -3780px; } 
.country-flag.lebanon { background-position: 0 -3810px; } 
.country-flag.lesotho { background-position: 0 -3840px; } 
.country-flag.liberia { background-position: 0 -3870px; } 
.country-flag.libya { background-position: 0 -3900px; } 
.country-flag.liechtenstein { background-position: 0 -3930px; } 
.country-flag.lithuania { background-position: 0 -3960px; } 
.country-flag.luxembourg { background-position: 0 -3990px; } 
.country-flag.macao { background-position: 0 -4020px; } 
.country-flag.madagascar { background-position: 0 -4050px; } 
.country-flag.madeira { background-position: 0 -4080px; } 
.country-flag.malawi { background-position: 0 -4110px; } 
.country-flag.malaysia { background-position: 0 -4140px; } 
.country-flag.maldives { background-position: 0 -4170px; } 
.country-flag.mali { background-position: 0 -4200px; } 
.country-flag.malta { background-position: 0 -4230px; } 
.country-flag.marshall-island { background-position: 0 -4260px; } 
.country-flag.martinique { background-position: 0 -4290px; } 
.country-flag.mauritania { background-position: 0 -4320px; } 
.country-flag.mauritius { background-position: 0 -4350px; } 
.country-flag.melilla { background-position: 0 -4380px; } 
.country-flag.mexico { background-position: 0 -4410px; } 
.country-flag.micronesia { background-position: 0 -4440px; } 
.country-flag.moldova { background-position: 0 -4470px; } 
.country-flag.monaco { background-position: 0 -4500px; } 
.country-flag.mongolia { background-position: 0 -4530px; } 
.country-flag.montenegro { background-position: 0 -4560px; } 
.country-flag.montserrat { background-position: 0 -4590px; } 
.country-flag.morocco { background-position: 0 -4620px; } 
.country-flag.mozambique { background-position: 0 -4650px; } 
.country-flag.myanmar { background-position: 0 -4680px; } 
.country-flag.namibia { background-position: 0 -4710px; } 
.country-flag.nato { background-position: 0 -4740px; } 
.country-flag.nauru { background-position: 0 -4770px; } 
.country-flag.nepal { background-position: 0 -4800px; } 
.country-flag.netherlands { background-position: 0 -4830px; } 
.country-flag.new-zealand { background-position: 0 -4860px; } 
.country-flag.nicaragua { background-position: 0 -4890px; } 
.country-flag.niger { background-position: 0 -4920px; } 
.country-flag.nigeria { background-position: 0 -4950px; } 
.country-flag.niue { background-position: 0 -4980px; } 
.country-flag.norfolk-island { background-position: 0 -5010px; } 
.country-flag.northen-cyprus { background-position: 0 -5040px; } 
.country-flag.northern-marianas-islands { background-position: 0 -5070px; } 
.country-flag.north-korea { background-position: 0 -5100px; } 
.country-flag.norway { background-position: 0 -5130px; } 
.country-flag.oman { background-position: 0 -5160px; } 
.country-flag.orkney-islands { background-position: 0 -5190px; } 
.country-flag.ossetia { background-position: 0 -5220px; } 
.country-flag.pakistan { background-position: 0 -5250px; } 
.country-flag.palau { background-position: 0 -5280px; } 
.country-flag.palestine { background-position: 0 -5310px; } 
.country-flag.panama { background-position: 0 -5340px; } 
.country-flag.papua-new-guinea { background-position: 0 -5370px; } 
.country-flag.paraguay { background-position: 0 -5400px; } 
.country-flag.peru { background-position: 0 -5430px; } 
.country-flag.philippines { background-position: 0 -5460px; } 
.country-flag.pitcairn-islands { background-position: 0 -5490px; } 
.country-flag.portugal { background-position: 0 -5520px; } 
.country-flag.puerto-rico { background-position: 0 -5550px; } 
.country-flag.qatar { background-position: 0 -5580px; } 
.country-flag.rapa-nui { background-position: 0 -5610px; } 
.country-flag.republic-of-macedonia { background-position: 0 -5640px; } 
.country-flag.republic-of-poland { background-position: 0 -5670px; } 
.country-flag.republic-of-the-congo { background-position: 0 -5700px; } 
.country-flag.romania { background-position: 0 -5730px; } 
.country-flag.russia { background-position: 0 -5760px; } 
.country-flag.rwanda { background-position: 0 -5790px; } 
.country-flag.saba-island { background-position: 0 -5820px; } 
.country-flag.saint-kitts-and-nevis { background-position: 0 -5850px; } 
.country-flag.salvador { background-position: 0 -5880px; } 
.country-flag.samoa { background-position: 0 -5910px; } 
.country-flag.san-marino { background-position: 0 -5940px; } 
.country-flag.sao-tome-and-principe { background-position: 0 -5970px; } 
.country-flag.sardinia { background-position: 0 -6000px; } 
.country-flag.saudi-arabia { background-position: 0 -6030px; } 
.country-flag.scotland { background-position: 0 -6060px; } 
.country-flag.senegal { background-position: 0 -6090px; } 
.country-flag.serbia { background-position: 0 -6120px; } 
.country-flag.seychelles { background-position: 0 -6150px; } 
.country-flag.sierra-leone { background-position: 0 -6180px; } 
.country-flag.singapore { background-position: 0 -6210px; } 
.country-flag.sint-eustatius { background-position: 0 -6240px; } 
.country-flag.sint-maarten { background-position: 0 -6270px; } 
.country-flag.slovakia { background-position: 0 -6300px; } 
.country-flag.slovenia { background-position: 0 -6330px; } 
.country-flag.solomon-islands { background-position: 0 -6360px; } 
.country-flag.somalia { background-position: 0 -6390px; } 
.country-flag.somaliland { background-position: 0 -6420px; } 
.country-flag.south-africa { background-position: 0 -6450px; } 
.country-flag.south-korea { background-position: 0 -6480px; } 
.country-flag.south-sudan { background-position: 0 -6510px; } 
.country-flag.spain { background-position: 0 -6540px; } 
.country-flag.sri-lanka { background-position: 0 -6570px; } 
.country-flag.st-barts { background-position: 0 -6600px; } 
.country-flag.st-lucia { background-position: 0 -6630px; } 
.country-flag.st-vincent-and-the-grenadines { background-position: 0 -6660px; } 
.country-flag.sudan { background-position: 0 -6690px; } 
.country-flag.suriname { background-position: 0 -6720px; } 
.country-flag.swaziland { background-position: 0 -6750px; } 
.country-flag.sweden { background-position: 0 -6780px; } 
.country-flag.switzerland { background-position: 0 -6810px; } 
.country-flag.syria { background-position: 0 -6840px; } 
.country-flag.taiwan { background-position: 0 -6870px; } 
.country-flag.tajikistan { background-position: 0 -6900px; } 
.country-flag.tanzania { background-position: 0 -6930px; } 
.country-flag.thailand { background-position: 0 -6960px; } 
.country-flag.tibet { background-position: 0 -6990px; } 
.country-flag.togo { background-position: 0 -7020px; } 
.country-flag.tokelau { background-position: 0 -7050px; } 
.country-flag.tonga { background-position: 0 -7080px; } 
.country-flag.transnistria { background-position: 0 -7110px; } 
.country-flag.trinidad-and-tobago { background-position: 0 -7140px; } 
.country-flag.tunisia { background-position: 0 -7170px; } 
.country-flag.turkey { background-position: 0 -7200px; } 
.country-flag.turkmenistan { background-position: 0 -7230px; } 
.country-flag.turks-and-caicos { background-position: 0 -7260px; } 
.country-flag.tuvalu { background-position: 0 -7290px; } 
.country-flag.uganda { background-position: 0 -7320px; } 
.country-flag.ukraine { background-position: 0 -7350px; } 
.country-flag.united-arab-emirates { background-position: 0 -7380px; } 
.country-flag.united-kingdom { background-position: 0 -7410px; } 
.country-flag.united-nations { background-position: 0 -7440px; } 
.country-flag.united-states-of-america { background-position: 0 -7470px; } 
.country-flag.uruguay { background-position: 0 -7500px; } 
.country-flag.uzbekistn { background-position: 0 -7530px; } 
.country-flag.vanuatu { background-position: 0 -7560px; } 
.country-flag.vatican-city { background-position: 0 -7590px; } 
.country-flag.venezuela { background-position: 0 -7620px; } 
.country-flag.vietnam { background-position: 0 -7650px; } 
.country-flag.virgin-islands { background-position: 0 -7680px; } 
.country-flag.wales { background-position: 0 -7710px; } 
.country-flag.western-sahara { background-position: 0 -7740px; } 
.country-flag.yemen { background-position: 0 -7770px; } 
.country-flag.zambia { background-position: 0 -7800px; } 
.country-flag.zimbabwe { background-position: 0 -7830px; }