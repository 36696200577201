.contentBg {
  background-color: $secondaryColor;
  padding: 15px;
  /* background: url(#{$img-path}/bg.jpg) no-repeat; */
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: $tab_Device){
    padding: 10px;
  }
}
.mainSearchbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
  background-color: #f5f5f5;
}
.mainSearchbar-leftSection ul {
  overflow: hidden;
  li {
    display: inline-block;
    margin-left: 15px;
    a {
      display: block;
      text-align: center;
      color: #001a69 !important;
      padding: 5px;
      font-weight: 600;

      &:hover {
        border-radius: 4px 4px 0 0;
        color: #000 !important;
      }
    }
    &.icons {
      font-size: 25px;
    }
    a.active {
      border-bottom: solid 3px #001a69;
    }
  }
}
.mainSearchbar-rightSection {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.strengthMeter {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
  line-height: 25px;
  color: #fff;
  &.green {
    background-color: #ffcc00;
  }
  &.orange {
    background-color: #dac045;
  }
  &.maroon {
    background-color: #47bba3;
  }
  &.purple {
    background-color: #f65d57;
  }
  &.lime {
    background-color: #f90;
  }
  &.pink {
    background-color: #6dc36d;
  }
  &.lightgreen {
    background-color: #0099ff;
  }
  &.lightblue {
    background-color: #6851df;
  }
  &.lightbrown {
    background-color: #777;
  }
}
.team-vs {
  display: flex;
  align-items: center;
  color: #f78a0a;
  font-size: 15px;
  font-weight: 600;
  padding: 0 5px;
  text-transform: none;
}